const SETTING = {
    prod: {
        // apiUrl: 'mahjong.sweetbot.online/',
        apiUrl: 'apis.boyplaymj.link/',
        firebaseConfig: {
            apiKey: "AIzaSyBpOo4gKdiueIBsqFwg69cK0N-v0xNqfYA",
            authDomain: "sml-game.firebaseapp.com",
            databaseURL: "https://sml-game-default-rtdb.firebaseio.com",
            projectId: "sml-game",
            storageBucket: "sml-game.appspot.com",
            messagingSenderId: "950607103327",
            appId: "1:950607103327:web:53f9d672f8b83d86b2a2ea",
            measurementId: "G-2WP3LY8X41"
        }
    },
    test: {
        apiUrl: 'apis.boyplaymj.link/',
        firebaseConfig: {
            apiKey: "AIzaSyATPypr1tgyKnG-8-J-Tn-Er8JKmKJSB7I",
            authDomain: "boyplaymj-daily.firebaseapp.com",
            databaseURL: "https://boyplaymj-daily-default-rtdb.firebaseio.com",
            projectId: "boyplaymj-daily",
            storageBucket: "boyplaymj-daily.appspot.com",
            messagingSenderId: "397030678993",
            appId: "1:397030678993:web:2c1feaab999225039e00f0",
            measurementId: "G-WWHYE539RC"
        }
    },
    stage: {
        apiUrl: 'https://apis.boyplaymj.link/',
        firebaseConfig: {
            apiKey: "AIzaSyATPypr1tgyKnG-8-J-Tn-Er8JKmKJSB7I",
            authDomain: "boyplaymj-daily.firebaseapp.com",
            databaseURL: "https://boyplaymj-daily-default-rtdb.firebaseio.com",
            projectId: "boyplaymj-daily",
            storageBucket: "boyplaymj-daily.appspot.com",
            messagingSenderId: "397030678993",
            appId: "1:397030678993:web:2c1feaab999225039e00f0",
            measurementId: "G-WWHYE539RC"
        }
    },
    getMSGurl: "https://www.googleapis.com/youtube/v3/liveChat/messages?",
    version: "1.0.0",
    thousandSeparator: true,
    playoffYear: 2021,
    playoffMonth: 1,
    playoffDate: 22,
    decPlaces: 0,
    decimal: 0,
    colorList: [
        'rgba(239,127,34,1)',
        'rgba(28,141,192,1)',
        'rgba(244,34,224,1)',
        'rgba(114,45,224,1)',
        'rgba(242,12,35,1)',
        'rgba(0,158,66,1)',
        'rgba(188,117,0,1)',
        'rgba(179,181,182,1)',
        'rgba(250, 250, 250,1)',
        'rgba(34, 34, 34,1)',
    ],
    playerKey: [ // Ctrl+Shift
        {id: 39, key: 'A', name: '韋智'},
        {id: 40, key: 'B', name: '韋孟'},
        {id: 29, key: 'C', name: '宜臻'},
        {id: 30, key: 'D', name: '杯尼'},
        {id: 21, key: 'E', name: '高高'},
        {id: 22, key: 'F', name: '小鄭'},
        {id: 13, key: 'G', name: '先先'},
        {id: 14, key: 'H', name: '阿億'},
        {id: 51, key: 'I', name: '鮭魚'},
        {id: 52, key: 'J', name: '肯尼'},
        {id: 23, key: 'K', name: '羅伊'},
        {id: 24, key: 'L', name: '泰迪'},
        {id: 57, key: 'M', name: '肉肉'},
        {id: 58, key: 'S', name: '小花'},
        {id: 59, key: 'O', name: '涵涵'},
        {id: 60, key: 'P', name: '西米'},
    ],
    teamKey: [ // Alt+Shift
        {id: 24, key: 'A', name: 'A'},
        {id: 25, key: 'B', name: 'B'},
        {id: 26, key: 'C', name: 'C'},
        {id: 27, key: 'D', name: 'D'},
        {id: 28, key: 'E', name: 'E'},
        {id: 29, key: 'F', name: 'F'},
        {id: 30, key: 'G', name: 'G'},
        {id: 31, key: 'H', name: 'H'},
    ]
}
module.exports = SETTING
